
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
    TmTooltip,
  },
  setup() {
    const regex = ref(true)
    const number = ref(true)
    const special = ref(true)

    const passwordRequirements = ref('8')
    const passwordExpiresOptions = ref([
      'Never',
      'After 30 days',
      'After 6 months',
      'After 1 year',
    ])
    const passwordExpires = ref(passwordExpiresOptions.value[0])

    return {
      regex,
      number,
      special,
      passwordRequirements,
      passwordExpiresOptions,
      passwordExpires,
    }
  },
})
